































































































































import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  watch
} from '~/utils/nuxt3-migration'
import MakeOptionWithLogoAndCount from '~/components/car/classifieds/search/facets/handlers/MakeModel/MakeOptionWithLogoAndCount.vue'
import { Option } from '~/models/shared/types'
import { useFormComponent } from '~/compositions/form-component'
import { SelectSize } from '~/models/app/select'
import { ciAngleDown } from '~/icons/source/regular/angle-down'
import { ciTimes } from '~/icons/source/regular/times'
import { elementIsOverflownInParent } from '~/utils/dom'
import { debounce } from '~/utils/function'

export default defineComponent({
  components: {
    MakeOptionWithLogoAndCount
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    headerClasses: {
      type: String,
      required: true
    },
    mainTextClasses: {
      type: String,
      default: ''
    },
    headerPreText: {
      type: String,
      default: null
    },
    selectedOptions: {
      type: Array as PropType<Option[]>,
      required: true
    },
    internalDropdownOpen: {
      type: Boolean,
      required: true
    },
    size: {
      type: String as PropType<SelectSize>,
      required: true
    },
    multiSelect: {
      type: Boolean,
      required: true
    },
    state: {
      type: Boolean,
      default: null
    },
    warning: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    nothingSelected: {
      type: Boolean,
      default: false
    },
    inGroup: {
      type: Boolean,
      default: false
    },
    useNativeSelect: {
      type: Boolean,
      required: true
    },
    textField: {
      type: String,
      default: 'text'
    }
  },
  setup(props, { emit }) {
    const hasError = ref(props.state === false)
    const hasSuccess = ref(props.state === true)

    watch(
      () => props.state,
      newState => {
        hasError.value = newState === false
        hasSuccess.value = newState === true
      }
    )

    const { controlClasses } = useFormComponent(
      ref(''),
      hasError,
      hasSuccess,
      ref(props.warning),
      'select'
    )

    const multiselectOptionsRefs = ref<HTMLElement[]>()
    const multiselectOptionsContainerRef = ref<HTMLElement>()
    const multiselectOverflownOptionsCount = ref()
    let resizeObserver: ResizeObserver

    onMounted(() => {
      if (
        !props.multiSelect ||
        !multiselectOptionsContainerRef.value ||
        !multiselectOptionsRefs.value ||
        !('ResizeObserver' in window)
      ) {
        return
      }

      resizeObserver = new ResizeObserver(calculateOverflowingOptionsCount)

      resizeObserver.observe(multiselectOptionsContainerRef.value)
    })

    onUnmounted(() => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    })

    watch(
      () => props.selectedOptions,
      () => {
        if (props.multiSelect) {
          calculateOverflowingOptionsCount()
        }
      }
    )

    const firstOptionText = computed(() => {
      const [firstOption] = selectedOptionSet.value

      if (typeof firstOption === 'string') {
        return firstOption
      }

      return firstOption.headerText || firstOption[props.textField]
    })

    const textColorClass = computed(() =>
      props.nothingSelected || props.disabled
        ? 'tw-text-grey-600'
        : 'tw-text-grey-900'
    )

    const computedClasses = computed((): any[] => {
      const classes = [props.headerClasses, props.size]

      if (props.disabled) {
        classes.push(
          ...['tw-bg-grey-300', 'tw-cursor-not-allowed', 'pointer-events-none']
        )
      } else {
        classes.push(...['tw-bg-white', 'tw-cursor-pointer'])
      }

      if (props.internalDropdownOpen) {
        if (props.state) {
          classes.push('tw-ring-green-500', '!tw-border-green-500')
        } else if (props.state === false) {
          classes.push('tw-ring-red-500', '!tw-border-red-500')
        } else if (props.warning) {
          classes.push('tw-ring-amber-500', '!tw-border-amber-500')
        } else {
          classes.push('tw-ring-blue-500', '!tw-border-blue-500')
        }
        classes.push('tw-ring-1')
      }

      if (!props.disabled) {
        if (props.state) {
          classes.push(
            'focus-visible:tw-ring-1 focus-visible:tw-ring-green-500'
          )
        } else if (props.state === false) {
          classes.push('focus-visible:tw-ring-1 focus-visible:tw-ring-red-500')
        } else if (props.warning) {
          classes.push(
            'focus-visible:tw-ring-1 focus-visible:tw-ring-amber-500'
          )
        } else {
          classes.push('focus-visible:tw-ring-1 focus-visible:tw-ring-blue-500')
        }
      }

      if (props.inGroup) {
        classes.push('in-group')
      }

      return [...classes, ...controlClasses.value, textColorClass.value]
    })

    const selectedOptionSet = computed((): Option[] | string[] => {
      const set = new Set<string>()
      const options = []
      for (const option of props.selectedOptions) {
        const duplicateName: boolean = set.has(option[props.textField])

        set.add(option[props.textField])

        if (duplicateName) {
          continue
        }

        options.push(option)
      }
      return options
    })

    const separatedSelectedOptions = computed(() =>
      selectedOptionSet.value
        .map(option =>
          typeof option === 'string'
            ? option
            : option.headerText || option[props.textField]
        )
        .join(', ')
    )

    function onClick() {
      emit('click')
    }

    const calculateOverflowingOptionsCount = debounce(async () => {
      if (
        !props.multiSelect ||
        !multiselectOptionsRefs.value ||
        !multiselectOptionsContainerRef.value
      ) {
        return
      }

      await nextTick()

      let count = 0

      for (const optionEl of multiselectOptionsRefs.value) {
        const optionIsOverflown = elementIsOverflownInParent(
          optionEl,
          multiselectOptionsContainerRef.value
        )

        if (optionIsOverflown) {
          count += 1
        }
      }

      multiselectOverflownOptionsCount.value = count
    }, 100)

    function onOptionRemoveClick(option: Option) {
      emit('remove-option', option)
    }

    return {
      ciAngleDown,
      ciTimes,
      selectedOptionSet,
      separatedSelectedOptions,
      computedClasses,
      textColorClass,
      firstOptionText,
      multiselectOptionsRefs,
      multiselectOptionsContainerRef,
      multiselectOverflownOptionsCount,
      onClick,
      onOptionRemoveClick
    }
  }
})
