import { CIcon } from '~/icons/types'

export const ciCart: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M16.9 22.7a2.8 2.8 0 1 0 0-5.6 2.8 2.8 0 0 0 0 5.6Zm-8.4-5.6c-1.6 0-2.8 1.3-2.8 2.8 0 1.5 1.3 2.8 2.8 2.8 1.5 0 2.8-1.3 2.8-2.8 0-1.5-1.2-2.8-2.8-2.8ZM22.6 4.9c-.1-.2-.4-.3-.6-.3H6.1l-.2-1.1c-.1-1.3-1.1-2.3-2.4-2.3H2c-.4 0-.8.4-.8.8s.4.8.8.8h1.5c.5 0 .9.4.9.9l.2 1.7v.3l1 9.7c0 .4.4.7.7.7h12.4c1.2 0 2.2-.9 2.4-2l1.5-8.4c.2-.4.1-.6 0-.8Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'cart',
  type: 'solid'
}
