
















































































import CShimmer from '~/components/shared/configurable/shimmer/CShimmer.vue'
import { getRandomNumberBetween } from '~/utils/number'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: {
    CShimmer
  },
  methods: {
    getRandomWidth() {
      const randomWidth = getRandomNumberBetween(20, 80)
      return `${randomWidth}%`
    }
  }
})
