



















































import { paramsValuesAsArrays, preprocessParams } from '~/utils/http'
import {
  CLASSIFIED_SEARCH_NS,
  SearchState
} from '~/store/modules/shared/classifieds/search/state'
import LegacySearchService from '~/services/search/LegacySearchService'
import Pagination from '~/components/car/classifieds/search/results/Pagination.vue'
import { defineComponent, computed } from '~/utils/nuxt3-migration'
import { defineComponentTranslations } from '~/utils/i18n'
import { ciTop } from '~/icons/source/regular/top'
import { useNamespacedStore } from '~/compositions/store'
import { useDeps } from '~/compositions/dependency-container'
import { useScroll } from '~/compositions/scroll'
import { useSearchBottomAds } from '~/compositions/ad'
import { useUserAgent } from '~/compositions/user-agent'
import CGoogleAd from '~/components/shared/configurable/ad/CGoogleAd.vue'
import CPosting from '~/components/shared/configurable/ad/CPosting.vue'

export default defineComponent({
  components: {
    CPosting,
    CGoogleAd,
    Pagination
  },
  setup() {
    const {
      state: classifiedSearchState,
      getters: classifiedSearchGetters
    } = useNamespacedStore<SearchState>(CLASSIFIED_SEARCH_NS)
    const [legacySearchService] = useDeps(LegacySearchService)
    const { scrollToTop } = useScroll()
    const { fullAd, leftAd, rightAd } = useSearchBottomAds()
    const { isPc } = useUserAgent()

    const loadingResults = computed(() => classifiedSearchState.loading.results)

    const params = computed(() => classifiedSearchState.params)

    const rootParams = computed(() => classifiedSearchState.config.rootParams)

    const total = computed(() => classifiedSearchState.pagination.total)

    const perPage = computed(() => classifiedSearchState.perPage)

    const rows = computed(() => classifiedSearchState.rows)

    const totalRows = computed(() => classifiedSearchGetters('getTotalRows'))

    const currentPage = computed(() =>
      classifiedSearchGetters('getResultsPaginationPage')
    )

    const showSellers = computed(() => classifiedSearchGetters('showSellers'))

    const adsKeyValues = computed(() =>
      classifiedSearchGetters('getAdsKeyValues')
    )

    const inXymaSearch = computed(() => classifiedSearchGetters('inXymaSearch'))

    const isJobsSearch = computed(() => classifiedSearchGetters('isJobsSearch'))

    const shouldDisplayAds = computed(() =>
      classifiedSearchGetters('shouldDisplayAds')
    )

    const showFullAd = computed(() => inXymaSearch.value || isJobsSearch.value)

    function onPaginationVisibilityChange(isVisible: boolean) {
      if (isVisible && currentPage.value * perPage.value < total.value) {
        prefetchNextPage()
      }
    }

    function prefetchNextPage() {
      if (showSellers.value) {
        return
      }

      const paramsToPrefetch = {
        ...rootParams.value,
        ...params.value,
        ...{
          pg: currentPage.value + 1,
          'per-page': perPage.value
        }
      }

      const preprocessedParams = paramsValuesAsArrays(
        preprocessParams(paramsToPrefetch)
      )

      return legacySearchService.getResults(preprocessedParams)
    }

    function onBackToTopButtonClick() {
      scrollToTop({ duration: 500, easing: 'ease-out' })
    }

    return {
      ciTop,
      totalRows,
      loadingResults,
      fullAd,
      leftAd,
      rightAd,
      isPc,
      adsKeyValues,
      showFullAd,
      shouldDisplayAds,
      rows,
      onPaginationVisibilityChange,
      onBackToTopButtonClick
    }
  },
  i18n: defineComponentTranslations({
    back_to_top: {
      en: 'Back to top',
      el: 'Μετάβαση στην αρχή'
    }
  })
})
