



























































import { debounce } from '~/utils/function'
import COptionWithCount from '~/components/shared/configurable/form/option/COptionWithCount.vue'
import { mapBackendSearchableToOption } from '~/services/search/formatters'
import { DEBOUNCE_DEFAULT, DEBOUNCE_SLOW } from '~/constants/debounce'
import ExpandableHandlerContent from '~/components/car/classifieds/search/facets/handlers/ExpandableHandlerContent.vue'
import KeyValueSearchHandler from '~/components/car/classifieds/search/facets/handlers/KeyValueSearchHandler.vue'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: {
    ExpandableHandlerContent,
    COptionWithCount
  },
  extends: KeyValueSearchHandler,
  data() {
    return {
      internalLoading: false,
      q: '',
      selectedValues: [],
      size: null,
      minDocCount: null,
      reverse: null
    }
  },
  computed: {
    options() {
      return (this.searchables || []).map(mapBackendSearchableToOption)
    },
    showSearchBar() {
      return (
        (this.searchables && this.searchables.length > 10) ||
        this.selected.query != null
      )
    },
    showMoreButton() {
      return this.selected.size <= this.searchables.length
    }
  },
  watch: {
    searchables() {
      this.internalLoading = false
    }
  },
  methods: {
    onBackendValues({ selected }) {
      const {
        size,
        query,
        values,
        min_doc_count: minDocCount,
        reverse
      } = selected
      this.q = query
      this.selectedValues = values
      this.size = size
      this.minDocCount = minDocCount
      this.reverse = reverse
    },
    onClearAll() {
      this.q = ''
      this.minDocCount = 1
      this.selectedValues = []
      this.reverse = false
    },
    onClear() {
      this.q = ''
      this.minDocCount = 1
      this.selectedValues = []
      this.reverse = false
      this.emitValues()
    },
    handleSelectedValuesChange(selectedValues: any[]) {
      this.selectedValues = selectedValues
      this.q = ''
      this.emitValues()
    },
    handleSearchQueryChange: debounce(function(q: string) {
      this.q = q
      this.emitValues()
    }, DEBOUNCE_DEFAULT),
    toggleReverse() {
      this.reverse = !this.reverse
      this.emitParams([
        {
          name: `${this.name}-r`,
          value: this.reverse
        }
      ])
    },
    handleMinDocCountChange: debounce(function(minDocCount: string) {
      this.minDocCount = minDocCount
      this.emitParams([
        {
          name: `${this.name}-m`,
          value: this.minDocCount
        }
      ])
    }, DEBOUNCE_SLOW),
    requestMore() {
      this.internalLoading = true
      this.size += 100
      this.emitValues()
    },
    emitValues() {
      const { q, size, minDocCount, selectedValues, reverse } = this
      this.emitParams([
        { name: `${this.name}-q`, value: q },
        { name: `${this.name}-s`, value: size },
        {
          name: `${this.name}-m`,
          value: minDocCount
        },
        {
          name: `${this.name}-r`,
          value: reverse
        },
        { name: this.name, value: selectedValues }
      ])
    }
  }
})
