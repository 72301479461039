import { CIcon } from '~/icons/types'

export const ciTop: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'm19.7 9.9-7-6.7c-.1-.1-.2-.1-.2-.2H12c-.1 0-.2 0-.3.1h-.1c-.1 0-.2.1-.2.2l-7 6.7c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0L11 6.3V20c0 .6.4 1 1 1s1-.4 1-1V6.3l5.3 5.1c.4.4 1 .4 1.4 0 .4-.4.4-1.1 0-1.5Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'top',
  type: 'regular'
}
