





import { computed, defineComponent, PropType } from '~/utils/nuxt3-migration'
import { useI18n } from '~/compositions/i18n'
import { defineComponentTranslations } from '~/utils/i18n'
import { CartProductAvailability } from '~/models/marketplace/cart'
import CBadge from '~/components/shared/configurable/badge/CBadge.vue'

export default defineComponent({
  components: { CBadge },
  props: {
    availability: {
      type: String as PropType<CartProductAvailability>,
      required: true
    },
    stockCount: {
      type: Number,
      default: null
    }
  },
  setup(props) {
    const { t } = useI18n()

    const badgeVariant = computed(() =>
      props.availability === 'out_of_stock' ? 'danger' : 'success'
    )

    const badgeText = computed(() => {
      if (props.stockCount !== null && props.stockCount > 0) {
        return t('available')
      }

      switch (props.availability) {
        case '1_3_days':
          return t('available_in_days', {
            daysFrom: 1,
            daysTo: 3
          })
        case '4_10_days':
          return t('available_in_days', {
            daysFrom: 4,
            daysTo: 10
          })
        case '10_30_days':
          return t('available_in_days', {
            daysFrom: 10,
            daysTo: 30
          })
        case 'out_of_stock':
          return t('not_available')
      }
    })

    return { badgeText, badgeVariant }
  },
  i18n: defineComponentTranslations({
    available: {
      en: 'Available',
      el: 'Διαθέσιμο'
    },
    not_available: {
      en: 'Not available',
      el: 'Μη διαθέσιμο'
    },
    available_in_days: {
      en: 'Available in {daysFrom} to {daysTo} days',
      el: 'Διαθέσιμο σε {daysFrom} έως {daysTo} ημέρες'
    }
  })
})
